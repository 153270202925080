.data__time {
    color: #80387D;
    font-family: Roboto;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.data__content {
    @apply border-l-2 border-[#80387D] ml-[5px] pl-3 py-4 md:py-2;
}

.data__content__title {
    @apply mt-3;
    display: flex;
    height: 3.125rem;
    padding-bottom: 0px;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
}

.data__title {
    color: #000;
    font-family: Roboto;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.5125rem;
    /* 95.714% */
    letter-spacing: -0.0525rem;
}

.data__date {
    @apply mt-1;
    color: #000;
    font-family: Roboto;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.data__current {
    @apply mt-4;
    display: flex;
    width: 11rem;
    height: 1.8125rem;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    color: #000;
    font-family: Roboto;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.875rem;
    /* 125% */
}

.data__icon {
    @apply my-auto flex justify-center px-2 md:px-0;
}

.data__spam__time {
    @apply w-3 h-3 rounded-full bg-[#80387D] my-1 mr-2;
}

@media (max-width: 767px) {
    .data__time {
        font-size: 1.125rem;
    }
    .data__content__title {
        height: 2.5rem;
    }
    .data__title {
        font-size: 1.5rem;
        line-height: 1.875rem;
    }
    .data__date {
        font-size: 1.125rem;
    }
    .data__current {
        width: 8rem;
        height: 1.125rem;
        font-size: 1.125rem;
        line-height: 1.125rem;
    }
}
    