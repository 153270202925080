.text {
  @apply leading-7 flex justify-around;
}

.text_green {
  color: #02A702;
  border-radius: 5px;
  transition: color 1s ease, background-color 1s ease;
}

.text_red {
  color: #ED1C24;
  border-radius: 5px;
  transition: color 1s ease, background-color 1s ease;
}

.valueContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.valueContainerGreen {
  color: #02A702;
}

.valueContainerRed {
  color: #ED1C24;
}

.arrowUp {
  position: relative;
  color: #02A702;
  font-weight: bold;
  margin-right: 5px;
}

.arrowDown {
  position: relative;
  color: #ED1C24;
  font-weight: bold;
  margin-right: 5px;
}
