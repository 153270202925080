.lineCustom:after {
    position: absolute;
    top: 50%;
    left: -1px;
    content: "";
    width: 1px;
    height: 80%;
    background: #d6d6d6;
    transform: translate(0, -50%);
}

.background_dollar_house {
    fill: linear-gradient(173deg, #020203 -0.55%, #F09135 62.32%) !important;
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.08)) !important;
}

.button_tabs {
    @apply h-0.7 bg-gray_ced-400 flex-shrink-0;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
}

.button_tabs_active {
    @apply text-green_ced-300 pointer-events-none;
}

.button_tabs_inactive {
    @apply text-[#80387D] hover:text-green_ced-300 hover:bg-gray_ced-600;
}

.button_tabs_inactive:hover {
    border-radius: 16px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
}

.alert_envivo {
    @apply my-auto h-3 w-3;
    display: inline-block;
    margin-right: 4px;
    border-radius: 50%;
    background-color: white;
    animation-iteration-count: infinite;

}

.twinkle {
    animation-name: twinkle-1;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes twinkle-1 {
    50% {
        transform: scale(0.5);
        opacity: 0.5;
    }
}

.custom__select2{
	@apply relative block z-10 rounded-md;
    background: #fff;
	border: 1px solid #ccc;
	color: #000;
	padding: 10px 15px;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.24) inset,
		0px 0px 2px 0px rgba(0, 0, 0, 0.12) inset;
}

.barra_violeta {
    @apply rounded-t-full;
    margin: 0 auto;
    width: 20px;
    height: 4px;
    background-color: #80387D;
    display: block; /* Asegura que la barra se muestre correctamente */
}

.barra_white {
    @apply rounded-t-full;
    margin: 0 auto;
    width: 20px;
    height: 4px;
    background-color: #ffffff;
    display: block; /* Asegura que la barra se muestre correctamente */
}