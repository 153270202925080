.root{
    @apply md:w-full;
}

.text {
    @apply text-2xl  leading-7 flex justify-around
}

.text_cot {
    @apply text-xl ml-3
}

.row_cot {
    @apply flex align-middle ml-0
}

.row_cost {
    @apply flex my-auto justify-end w-full
}

.row_cost:nth-child(1) {
    @apply text-center
}

.row_cost:nth-child(2) {
    @apply  text-center
}

.barra_violeta {
    @apply rounded-t-full;
    margin: 0 auto;
    width: 20px;
    height: 4px;
    background-color: #80387D;
    display: block; /* Asegura que la barra se muestre correctamente */
}