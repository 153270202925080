/** @format */

.button_tabs {
	@apply w-38 py-1.5 bg-[#F9F9F9] text-[#0096A6] rounded-full shadow-sm flex-shrink-0 font-medium;
	border: 0.5px solid #ededed;
}

.button_tabs_active {
	@apply text-[#0096A6] pointer-events-none bg-[#F9F9F9] hover:bg-green_ced-400 hover:text-white;
}

.button_tabs_inactive {
	@apply text-[#8E8E8E] hover:shadow-md hover:bg-green_ced-400 hover:text-white;
}

.background_dollar_house {
	fill: linear-gradient(173deg, #020203 -0.55%, #f09135 62.32%);
	filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.08));
}

.button__pagination {
	@apply w-8 h-8 rounded-full border-2 border-green_ced-300 text-green_ced-300;
}

.button__pagination_active {
    @apply text-white pointer-events-none bg-purple_ced-100 border-purple_ced-300;
}

.button__pagination_inactive {
    @apply hover:border-green_ced-500 hover:bg-green_ced-500 hover:text-white;
}
/* animations.css */

.modal_content {
  background-color: white;
  border-radius: 8px;
  border: 1px solid var(--gray5);
}

.barra_violeta {
    @apply rounded-t-full;
    margin: 0 auto;
    width: 20px;
    height: 4px;
    background-color: #80387D;
    display: block; /* Asegura que la barra se muestre correctamente */
}




