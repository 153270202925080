.custom__select {
    @apply relative block w-full md:w-[461px] z-10 ;
    background: #fff;
	border: 1px solid #ccc;
	color: #000;
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	padding: 15px 15px;
	width: 100%;
	fill: #fafafa;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.24) inset,
		0px 0px 2px 0px rgba(0, 0, 0, 0.12) inset;
}

.custom__select2 {
	@apply relative block w-full md:w-[461px] z-10 rounded-md;
    background: #fff;
	border: 1px solid #ccc;
	color: #000;
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	padding: 10px 15px;
	width: 100%;
	fill: #fafafa;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.24) inset,
		0px 0px 2px 0px rgba(0, 0, 0, 0.12) inset;
}

.custom__content {
    @apply absolute block w-full  z-20 ;
    border: 1px solid #ccc;
    background: #fff;

}

.custom__content2 {
    @apply absolute block w-full  z-20 rounded-md mt-4 ;
    border: 1px solid #ccc;
    background: #fff;

}

.custom__option {
	color: #000;
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	padding: 15px 15px;
	fill: #fafafa;
}

.custom__option__selected {
	@apply bg-gray-200;
	color: #000;
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	padding: 15px 15px;
	
}

.custom__option:hover {
    @apply bg-gray-200;
}

/* .custom__option:nth-child(2),
.custom__option:nth-child(4) {
    @apply bg-gray-200;
} */

.custom__icon {
    @apply absolute w-[10px] h-[10px] right-3 md:ml-[420px]  ;
}
