
  .banner_container {
    padding: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 50px;
    height: 50px;
    overflow: hidden;
  }
  .banner_content {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: nowrap;
    overflow: hidden;
  }
  
  .banner_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .banner_image {
    height:  48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  
  .banner_image img {
    max-height: 100%;
    height: auto;
    object-fit: contain;

  }

  .close_button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 5px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }